import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./stylesheets/Footer.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

export default function Footer() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <footer id="footer" className="p-5">
            <Row>
                <Col>
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    <Link>Robert</Link>. All Rights Reserved.
                </Col>
                <Col>
                    <Link onClick={handleShow}>Privacy Policy</Link>
                </Col>
                <Modal
                    show={show}
                    onHide={handleClose}
                    style={{ "--bs-modal-width": "650px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Privacy Policy</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Privacy Statement for robgordtech.com</h5>
                        <p>
                            This Privacy Statement outlines the privacy
                            practices regarding the information displayed on{" "}
                            <a href="https://robgordtech.com">RobGordTech</a>.
                        </p>
                        <hr />
                        <h5>1. Protection of Personal Information</h5>
                        <p>
                            I am committed to safeguarding my contact and
                            address information displayed on this Website. I do
                            not collect personal information from visitors. My
                            primary purpose is to provide information and
                            content without requesting or storing any personal
                            data from you.
                        </p>
                        <hr />
                        <h5>2. Unauthorized Use</h5>
                        <p>
                            Unauthorized use of my contact/address information,
                            such as for profit or other malicious purposes, is
                            strictly prohibited. Any unauthorized attempts to
                            collect or use my information will be dealt with in
                            accordance with applicable laws.
                        </p>
                        <hr />
                        <h5>3. Contact</h5>
                        <p>
                            If you have any questions or concerns regarding the
                            information presented on this Website or believe
                            that unauthorized use of my information has
                            occurred, please contact me at{" "}
                            <a href="mailto:robert.gordiyevych@gmail.com">
                                robert.gordiyevych@gmail.com
                            </a>
                            .
                        </p>
                        <hr />
                        <h5>4. Changes to this Privacy Statement</h5>
                        <p>
                            I may update this Privacy Statement to reflect
                            changes in my practices or for other operational,
                            legal, or regulatory reasons. The updated statement
                            will be posted on this page.
                        </p>
                    </Modal.Body>
                </Modal>
            </Row>
        </footer>
    );
}
