import React from "react";
import "../stylesheets/SectionHeader.css";

export default function SectionHeader({
    textMuted,
    opaque,
    behindText,
    children,
}) {
    return (
        <div className="position-relative d-flex text-center mb-5">
            <h2
                className={`backText text-white fw-600 w-100 mb-0 text-uppercase ${
                    textMuted ? "text-muted" : "text-white-50"
                } ${opaque ? "opaqueText" : null}`}
            >
                {behindText}
            </h2>
            <p className=" mainText text-white position-absolute w-100 align-self-center lh-base mb-0">
                {children}
                <span className="bottomUnderline d-block mx-auto"></span>
            </p>
        </div>
    );
}
