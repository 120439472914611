import React, { forwardRef } from "react";

import SectionHeader from "../components/SectionHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ProgressBar from "react-bootstrap/ProgressBar";

import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BoltIcon from "@mui/icons-material/Bolt";

import "../stylesheets/Main.css";

export default forwardRef(function Resume({}, resume) {
    return (
        <section ref={resume} className="py-5">
            <Container>
                <SectionHeader textMuted behindText="Summary">
                    Resume
                </SectionHeader>
                <Row xs={1} md={2}>
                    <Col>
                        <h1 className="m-3">Experience</h1>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Header>
                                <Card.Title>
                                    Sounders Trucking | IT Technician
                                </Card.Title>
                                <Card.Subtitle>2020 - 2022</Card.Subtitle>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text className="align-start">
                                    Key responsibilities:
                                </Card.Text>
                                <ul className="align-start">
                                    <li>
                                        Set up and manage office computers and
                                        network infrastructure
                                    </li>
                                    <li>Build an informative Website</li>
                                    <li>
                                        Install security cameras around the
                                        perimeter
                                    </li>
                                    <li>
                                        Teach myself and train head mechanic on
                                        how to use different diagnostic software
                                        used for repair
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>

                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Header>
                                <Card.Title>
                                    Alpine Coffee Inc | Web Developer/IT
                                </Card.Title>
                                <Card.Subtitle>2022 - 2023</Card.Subtitle>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text className="align-start">
                                    Key responsibilities:
                                </Card.Text>
                                <ul className="align-start">
                                    <li>
                                        Create and continuously update website
                                        as startup business expanded
                                    </li>
                                    <li>
                                        Program recipes into the coffee vending
                                        machines and connect them to the payment
                                        system
                                    </li>
                                    <li>
                                        Assist in any technical difficulties
                                        when it came to integrating the machines
                                        into the stands
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>

                        <h1 className="m-3">Soft Skills</h1>
                        <Card bg="dark" text="light" border="light">
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <TroubleshootIcon />
                                    Problem-Solving
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <GroupsIcon />
                                    Team Player
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <EmojiPeopleIcon />
                                    Hard Worker
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <BoltIcon />
                                    Quick Learner
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <h1 className="m-3">Hard Skills</h1>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    Python
                                </Card.Title>
                                <ProgressBar
                                    animated
                                    now={55}
                                    variant="danger"
                                />
                            </Card.Body>
                            <Card.Footer>2 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    HTML/CSS
                                </Card.Title>
                                <ProgressBar
                                    animated
                                    now={95}
                                    variant="success"
                                />
                            </Card.Body>
                            <Card.Footer>+4 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    Javascript
                                </Card.Title>
                                <ProgressBar
                                    animated
                                    now={75}
                                    variant="warning"
                                />
                            </Card.Body>
                            <Card.Footer>+3 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    Bootstrap
                                </Card.Title>
                                <ProgressBar animated now={70} variant="info" />
                            </Card.Body>
                            <Card.Footer>3 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    React JS
                                </Card.Title>
                                <ProgressBar
                                    animated
                                    now={60}
                                    variant="primary"
                                />
                            </Card.Body>
                            <Card.Footer>+2 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    React Native
                                </Card.Title>
                                <ProgressBar
                                    animated
                                    now={40}
                                    variant="danger"
                                />
                            </Card.Body>
                            <Card.Footer>+1 years</Card.Footer>
                        </Card>
                        <Card
                            bg="dark"
                            text="light"
                            border="light"
                            className="my-3"
                        >
                            <Card.Body>
                                <Card.Title
                                    className="align-start"
                                    id="card-title-extra"
                                >
                                    Flutter
                                </Card.Title>
                                <ProgressBar animated now={35} variant="info" />
                            </Card.Body>
                            <Card.Footer>1 years</Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
});
