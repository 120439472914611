import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";

import WeightLogAppPP from "./apps/WeightLogPrivacyPolicy";
import NoPage from "./pages/NoPage";

import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "weightlogprivacypolicy",
        element: <WeightLogAppPP />,
    },
    {
        path: "*",
        element: <NoPage />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
