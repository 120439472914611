import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

export default function NoPage() {
    return (
        <div style={{ width: "100%", height: "100vh" }} className="bg-dark">
            <Container>
                <h1>Error 404:</h1>
                <p>
                    The page you are looking for either does't exist anymore or
                    the URL is wrong.
                </p>
                <img src="https://http.cat/images/404.jpg" alt="404 cat" />
            </Container>
            <button>
                <Link style={{ textDecoration: "none", color: "black" }} to="/">
                    Go to Home
                </Link>
            </button>
        </div>
    );
}
