import React, { forwardRef } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";

export default forwardRef(function Contact({}, contact) {
    return (
        <section ref={contact} className="py-5">
            <Row className="p-5">
                <Col md={6}>
                    <h3 className="text-danger">LOCATED IN:</h3>
                    <p>
                        <PlaceIcon className="mx-1" />
                        Dallas, GA
                    </p>

                    <p>
                        <PhoneIcon className="mx-1" />
                        Phone: <a href="tel:+16786740328">(678) 674-0328</a>
                    </p>
                    <p>
                        <EmailIcon className="mx-1" />
                        Email:{" "}
                        <a href="mailto:robert.gordiyevych@gmail.com">
                            robert.gordiyevych@gmail.com
                        </a>
                    </p>
                </Col>
                <Col md={6}>
                    <h3 className="text-danger">FOLLOW ME</h3>
                    <p>
                        <FacebookIcon />
                        <a
                            href="https://www.facebook.com/robert.gordiyevych"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mx-2"
                            style={{ color: "white" }}
                        >
                            Facebook
                        </a>
                    </p>
                    <p>
                        <GitHubIcon />
                        <a
                            href="https://github.com/RoBoT095"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mx-2"
                            style={{ color: "white" }}
                        >
                            Github
                        </a>
                    </p>
                    <p>
                        <InstagramIcon />
                        <a
                            href="https://www.instagram.com/robgord12/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mx-2"
                            style={{ color: "white" }}
                        >
                            Instagram
                        </a>
                    </p>
                </Col>
            </Row>
        </section>
    );
});
