import React from "react";
// import { Link } from "react-router-dom";
import "./stylesheets/Navbar.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function CollapsibleNavbar({
    scrollToSection,
    home,
    about,
    resume,
    portfolio,
    contact,
}) {
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            // className="bg-body-tertiary"
            style={{ backgroundColor: "#e7f7fa" }}
            id="Navbar"
        >
            <Container style={{ color: "white !important" }}>
                <Navbar.Brand
                    onClick={() => {
                        scrollToSection(home);
                    }}
                >
                    RobGordTech
                </Navbar.Brand>

                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ color: "white !important" }}
                />
                <Navbar.Collapse id="responsive-navbar-nav" className="mx-5">
                    <Nav className="me-auto">
                        <Nav.Link
                            duration={500}
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollToSection(home)}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            duration={500}
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollToSection(about)}
                        >
                            About Me
                        </Nav.Link>
                        <Nav.Link
                            duration={500}
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollToSection(resume)}
                        >
                            Resume
                        </Nav.Link>
                        <Nav.Link
                            duration={500}
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollToSection(portfolio)}
                        >
                            Portfolio
                        </Nav.Link>

                        <Nav.Link
                            duration={500}
                            style={{ cursor: "pointer" }}
                            onClick={() => scrollToSection(contact)}
                        >
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
